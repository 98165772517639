import Listagem from '../componentes/Emplacar/Listagem';
import Cadastro from '../componentes/Emplacar/Cadastro';

function Emplacar(props) {  
  
  return (

    <section>

      { props.acao === "Listar" &&
        <Listagem />
      }
      { props.acao !== "Listar" &&
        <Cadastro acao={props.acao} />
      }    

    </section>

  );

}

export default Emplacar;
