import axios from "axios";

//const URL = 'http://localhost:3001/filiado/veiculo';
const URL = 'https://conexaodetranpr.sindilocpr.com.br/api/filiado/veiculo';

const veiculosAPI = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
        Content: 'application/json'
    }
})

veiculosAPI.interceptors.request.use(function (config) {
    const Token = sessionStorage.getItem('token');
    if((Token) && (config.headers)){
        config.headers.Authorization = `Bearer ${Token}`
    }
    return config;
}, function (error) {
    console.log("Erro na API do Veículo");
    return Promise.reject(error);
});


veiculosAPI.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});


export {
    veiculosAPI
};