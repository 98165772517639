import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { filiadoAPI } from "../../servicos/filiado";
import { frotistaAPI } from '../../servicos/frotista';
import styled from "styled-components";

function Editar() {

    const Divbtn = styled.div`
        vertical-align: baseline;
        cursor: pointer;
    `     
    const breadcrumbs = ['Admin', 'Filiado', 'Edição'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');
    
    const [fi100_dsc_filiado, set100_dsc_filiado] = useState('');
    const [fi100_num_cnpj, set100_num_cnpj] = useState('');
    const [fi100_dsc_email, set100_dsc_email] = useState('');
    const [fi100_num_telefone, set100_num_telefone] = useState('');
    const [fi100_key_detran, set100_key_detran] = useState('');
    const [fi100_dsc_senha_detran, set100_dsc_senha_detran] = useState('');
    const [fi100_dsc_detran_aprovado, set100_dsc_detran_aprovado] = useState('');

    const [exibeProva, setExibeProva] = useState(false);

    let navigate = useNavigate();
    const Voltar = () => {
        let path = '/admin/filiado';
        navigate(path);
    }

    const parametros = useParams();

    useEffect(() => {
        if(parametros.hash) {
            buscarFiliado(parametros.hash);    
        }
    }, [parametros])

    async function buscarFiliado(hash){

        filiadoAPI.get(`/${hash}`)
            .then(resposta => {

                set100_dsc_filiado(resposta.data.fi100_dsc_filiado);
                set100_num_cnpj(resposta.data.fi100_num_cnpj);
                set100_dsc_email(resposta.data.fi100_dsc_email);
                set100_num_telefone(resposta.data.fi100_num_telefone);
                set100_key_detran(resposta.data.fi100_key_detran);
                set100_dsc_senha_detran(resposta.data.fi100_dsc_senha_detran);
                
                if((resposta.data.fi100_dsc_detran_aprovado !== "") && (resposta.data.fi100_dsc_detran_aprovado !== null)){
                    set100_dsc_detran_aprovado(resposta.data.fi100_dsc_detran_aprovado);
                    if(
                        (resposta.data.fi100_key_detran !== "") && 
                        (resposta.data.fi100_key_detran !== null) && 
                        (resposta.data.fi100_dsc_senha_detran !== "") && 
                        (resposta.data.fi100_dsc_senha_detran !== null)
                    ){
                        //setExibeProva(true);
                        setExibeProva(false);
                    }else{
                        setExibeProva(false);
                    }

                }else{
                    set100_dsc_detran_aprovado("Prova não realizada.");
                    //setExibeProva(true);
                    setExibeProva(false);
                }
                
            
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )
    }    

    function ValidaProva(){

        if (fi100_key_detran === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Detran-PR Cliente ID.");
            return false;
        }

        if (fi100_dsc_senha_detran === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Detran-PR Secret.");
            return false;
        }

        ValidaProvaCapacidade();
        
    }

    async function ValidaProvaCapacidade(){

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        const hash = parametros.hash;

        frotistaAPI.put(`/prova/${hash}`)
            .then(resposta => {
               
                if((resposta.data.fi100_dsc_detran_aprovado !== "") && (resposta.data.fi100_dsc_detran_aprovado !== null)){
                    set100_dsc_detran_aprovado(resposta.data.fi100_dsc_detran_aprovado);

                    setExibeSucesso(true);
                    setMensagemSucesso('Prova Realizada com Sucesso.');
            
                    setExibeProva(false);                
                }else{
                    setExibeErro(true);
                    setMensagemErro('Falha ao Realizar a Prova');
            
                    set100_dsc_detran_aprovado("Prova não realizada.");
                    setExibeProva(true);
                }
                
            
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )

    }    

    const validaForm = (event) => {
        event.preventDefault();

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if (fi100_dsc_filiado === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o nome.");
            return false;
        }

        if (fi100_dsc_email === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o e-mail.");
            return false;
        }

        if(parametros.hash){
            SalvarFiliado(parametros.hash);
        }else{
            setExibeErro(true);
            setMensagemErro("OPS Desconheço o cadastro deste filiado.");
            return false;
        }
        
    }

    async function SalvarFiliado(hash) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        filiadoAPI.put(`/editar/${hash}`,
            {
                'fi100_dsc_filiado': fi100_dsc_filiado,
                'fi100_num_cnpj': fi100_num_cnpj,
                'fi100_dsc_email': fi100_dsc_email,
                'fi100_num_telefone': fi100_num_telefone,
                'fi100_key_detran': fi100_key_detran,
                'fi100_dsc_senha_detran': fi100_dsc_senha_detran
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Filiado alterado com sucesso.');
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )


    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Editar Filiado' breadcrumbs={breadcrumbs} btnincluir={false} admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="fi100_dsc_filiado" placeholder="" name="fi100_dsc_filiado" value={fi100_dsc_filiado} onChange={evento => set100_dsc_filiado(evento.target.value)} />
                                <label for="floatingInput">Nome do Filiado</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="fi100_num_cnpj" placeholder="00.000.000/0001-00" name="fi100_num_cnpj" value={fi100_num_cnpj} onChange={evento => set100_num_cnpj(evento.target.value)} />
                                <label for="floatingInput">CNPJ</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="email" class="form-control" id="fi100_dsc_email" placeholder="name@example.com" name="fi100_dsc_email" value={fi100_dsc_email} onChange={evento => set100_dsc_email(evento.target.value)} />
                                <label for="floatingInput">E-mail</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="fi100_num_telefone" placeholder="(00) 00000-0000" name="fi100_num_telefone" value={fi100_num_telefone} onChange={evento => set100_num_telefone(evento.target.value)} />
                                <label for="floatingInput">Telefone</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="text" class="form-control" id="fi100_key_detran" placeholder="" name="fi100_key_detran" value={fi100_key_detran} onChange={evento => set100_key_detran(evento.target.value)} />
                                <label for="floatingInput">Detran-PR Cliente ID</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="text" class="form-control" id="fi100_dsc_senha_detran" placeholder="" name="fi100_dsc_senha_detran" value={fi100_dsc_senha_detran} onChange={evento => set100_dsc_senha_detran(evento.target.value)} />
                                <label for="floatingInput">Detran-PR Client Secret</label>
                            </div>
                            {exibeProva &&    
                            <div class="col-md-6 form-floating">
                                <input type="text" class="form-control" id="fi100_dsc_detran_aprovado" placeholder="" name="fi100_dsc_detran_aprovado" value={fi100_dsc_detran_aprovado} readOnly disabled/>
                                <label for="floatingInput">Detran-PR Prova de conceito</label>
                            </div>            
                            }                   
                            {exibeProva &&                                
                                <Divbtn class="col-md-6 form-floating" onClick={ValidaProva} >
                                    <a class="dropdown-item align-icon"><span class="material-symbols-outlined">edit</span>Prova</a>
                                </Divbtn>   
                            }                            
                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Editar;

