import { useEffect, useState } from "react";
import { emplacarAPI } from "../../servicos/emplacar";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import ListagemOpcoes from "../Listagem/opcoes";
import Paginacao from "../Listagem/paginacao";
import UsuarioCard from "./card";

function EmplacarTabela() {

    const breadcrumbs = ['Home', 'Emplacar'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [PesquisaVeiculo, setPesquisaVeiculo] = useState([]);

    useEffect(() => {
        fetchVeiculos()
    }, []);

    async function fetchVeiculos() {

        const fi100_key_public = sessionStorage.getItem('key');
        emplacarAPI.get(`/${fi100_key_public}`)
            .then(resposta => {
                setPesquisaVeiculo(resposta.data);
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
            })

    }

    let navigate = useNavigate();

    async function DesativarEmplacamento(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        emplacarAPI.patch(`/desativar/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Processo desativado com sucesso.');
                fetchVeiculos();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })
    }
    
    async function FinalizarEmplacamento(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        emplacarAPI.patch(`/Finalizar/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Processo finalizado com sucesso.');
                fetchVeiculos();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })
    }

    async function EnviarDetran(id) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        emplacarAPI.get(`/Processo/${id}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Processo (' + resposta.data.emplacar.ve101_num_processo + ') criado com sucesso. Clique no número para salvar a guia.');
                fetchVeiculos();
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if ((erro.response?.status === 401) || ((erro.response?.status === 406) && erro?.response?.data?.message === "Token Inválido") ) {                        
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setMensagemErro(erro.response.data.message);
                    }
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                fetchVeiculos();
            })

    }

    async function BuscarDocumentos(id) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        emplacarAPI.get(`/Documento/${id}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Processo (' + resposta.data.emplacar.ve101_num_processo + ') criado com sucesso. Clique no botão de Download.');
                //fetchVeiculos();
            })
            .catch(erro => {
                setExibeErro(true);
                if (erro?.response?.data?.message) {                    
                    if ((erro.response?.status === 401) || ((erro.response?.status === 406) && erro?.response?.data?.message === "Token Inválido") ) {                        
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setMensagemErro(erro.response.data.message);
                    }
                } else {
                    if (erro.response?.status === 401) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                fetchVeiculos();
            })                


    }    
    
    function FormatarDataHora(string){
        
        string = string.split("T");        
        var dataaux = string[0].split("-");
        var dataformatada = dataaux[2] + '/' + dataaux[1] + "/" + dataaux[0];      
        var retorno = dataformatada + ' ' + string[1].substring(0,5) ;

        return retorno;

    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Emplacar' breadcrumbs={breadcrumbs} btnincluir={true} destino='/filiado/emplacar/novo' admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            />
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Seq/Data</th>
                                    <th class="topo" scope="col">NF/Chassi</th>
                                    <th class="topo" scope="col">Processo</th>
                                    <th class="topo" scope="col">Documentos</th>
                                    <th class="topo" scope="col">Data/Status</th>
                                    <th class="topo" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {PesquisaVeiculo.map(veiculo => (
                                    <tr>
                                        <td>
                                            {veiculo.ve101_cod_emplacar}<br />
                                            <span class="email">
                                                {FormatarDataHora(veiculo.createdAt)}
                                            </span>
                                        </td>
                                        <td>
                                            {veiculo.ve101_key_nfe}<br />
                                            <span class="email">
                                                {veiculo.ve101_dsc_chassi}
                                            </span>
                                        </td>
                                        <td>
                                            <a href={veiculo.ve101_url_processo} target="_blank">{veiculo.ve101_num_processo}</a><br />
                                        </td>
                                        <td>
                                            {veiculo.ve101_url_autorizacao != null &&
                                                <a href={veiculo.ve101_url_autorizacao} target="_blank"><span class="material-symbols-outlined">Inventory</span></a>
                                            }
                                            {veiculo.ve101_url_crv != null &&
                                                <a href={veiculo.ve101_url_crv} target="_blank"><span class="material-symbols-outlined">Clarify</span></a>
                                            }
                                            {veiculo.ve101_url_seguranca != null &&
                                                <a href={veiculo.ve101_url_seguranca} target="_blank"><span class="material-symbols-outlined">Lock</span></a>
                                            }
                                        </td>
                                        <td>                                            
                                            {FormatarDataHora(veiculo.updatedAt)}<br />                                           

                                            {veiculo.ve101_dsc_situacao === "DETRANPR: VEICULO JA EMPLACADO NA BIN. (1043)" ? (
                                                <span class="email" style={{color:'#EA3323'}}>
                                                    <span class="material-symbols-outlined" style={{color:'#EA3323',verticalAlign:'bottom'}}>Error</span>
                                                    {veiculo.ve101_dsc_situacao}
                                                </span>
                                            ) : (
                                                veiculo.ve101_dsc_situacao === "DetranPR informa que a Fábrica/Concessionário ainda não realizou o comunicado de venda. Entre em contato com o vendedor. (RENAVE)" ? (
                                                    <span class="email" style={{color:'#A96424'}}>
                                                        <span class="material-symbols-outlined" style={{color:'#A96424',verticalAlign:'bottom'}}>Warning</span>
                                                        {veiculo.ve101_dsc_situacao}
                                                    </span>
                                                ) : (
                                                    veiculo.ve101_dsc_situacao === "Processo de emplacamento criado. Aguardando pagamento da guia." ? (
                                                        <span class="email" style={{color:'#166600'}}>
                                                            <span class="material-symbols-outlined" style={{color:'#166600',verticalAlign:'bottom'}}>price_check
                                                            </span>
                                                            {veiculo.ve101_dsc_situacao}
                                                        </span>
                                                    ) : (
                                                        veiculo.ve101_dsc_situacao === "DetranPR: Pagamento ainda não identificado/processado. Aguarde 2 horas para confirmação do PIX." ? (
                                                            <span class="email" style={{color:'#A96424'}}>
                                                                <span class="material-symbols-outlined" style={{color:'#A96424',verticalAlign:'bottom'}}>price_check
                                                                </span>
                                                                {veiculo.ve101_dsc_situacao}
                                                            </span>
                                                        ) : (
                                                            veiculo.ve101_dsc_situacao === "Documentos Gerados com Sucesso." ? (
                                                                <span class="email" style={{color:'#166600'}}>
                                                                    <span class="material-symbols-outlined" style={{color:'#166600',verticalAlign:'bottom'}}>done_all
                                                                    </span>
                                                                    {veiculo.ve101_dsc_situacao}
                                                                </span>
                                                            ) : (
                                                                <span class="email">
                                                                    {veiculo.ve101_dsc_situacao}
                                                                </span>
                                                            )            
                                                            )            
                                                    )        
                                                )    
                                            )}
                                        </td>
                                        <td style={{verticalAlign:'bottom'}}>
                                            <div class="dropdown">
                                                <button class="dropdown-acao btn-dropdown btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="material-symbols-outlined">more_vert</span>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li>
                                                        {veiculo.ve101_url_processo === null &&
                                                            <a class="dropdown-item align-icon" onClick={() => EnviarDetran(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">edit</span>Registrar
                                                            </a>
                                                        }
                                                        {veiculo.ve101_url_processo === null &&
                                                            <a class="dropdown-item align-icon action-disable" onClick={() => DesativarEmplacamento(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">do_not_disturb_on</span>Desativar
                                                            </a>
                                                        }
                                                        {veiculo.ve101_url_crv === null && veiculo.ve101_url_processo != null &&
                                                            <a class="dropdown-item align-icon" onClick={() => BuscarDocumentos(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">edit</span>Documentos
                                                            </a>
                                                        }
                                                        {veiculo.ve101_url_crv != null && veiculo.ve101_url_processo != null &&
                                                            <a class="dropdown-item align-icon" onClick={() => FinalizarEmplacamento(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">edit</span>Finalizar
                                                            </a>
                                                        }

                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <UsuarioCard />
            <Paginacao esconder={true} />
        </div>
    )

}

export default EmplacarTabela;