import Logo from '../Logo';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function MenuLateral(props){
    
    const admin = props.admin;

    const [MenuFiliado, setMenuFiliado] = useState('');
    const [MenuUsuario, setMenuUsuario] = useState('');
    const [MenuVeiculo, setMenuVeiculo] = useState('');
    const [MenuEmplacar, setMenuEmplacar] = useState('');
    
    useEffect(() => {
        AtivarMenu();
    }, []);

    function AtivarMenu(){

        setMenuFiliado('');
        setMenuUsuario('');
        setMenuVeiculo('');
        setMenuEmplacar('');

        if(props.menu === "filiado"){
            setMenuFiliado("active");
        }

        if(props.menu === "usuario"){
            setMenuUsuario("active");
        }

        if(props.menu === "veiculo"){
            setMenuVeiculo("active");
        }

        if(props.menu === "emplacar"){
            setMenuEmplacar("active");
        }

    }

    return (
        <section>
            <div class="col-12 col-lg-2 sidebar mobile-hide tablet-hide">  
                <Link to={'/filiado'} >
                    <Logo />
                </Link>               
                { admin &&
                    <ul class="gap-3">
                        <li id={MenuFiliado}>
                            <Link to={'/admin/filiado'} >
                                <span class="material-symbols-outlined">store</span>Filiados
                            </Link>
                        </li>
                        <li id={MenuUsuario}>
                            <Link to={'/admin/usuario'} >
                                <span class="material-symbols-outlined">person</span>Usuários
                            </Link>
                        </li>
                        <li>
                            <a href="#">
                                <span class="material-symbols-outlined">bar_chart_4_bars</span>Relatórios
                            </a>
                        </li>
                    </ul>                
                }
                { !admin &&
                    <ul class="gap-3">
                        <li id={MenuEmplacar}>
                            <Link to={'/filiado/emplacar'} >
                                <span class="material-symbols-outlined">directions_car</span>Emplacar
                            </Link>
                        </li>
                        <li id={MenuVeiculo}>
                            <Link to={'/filiado/veiculo'} >
                                <span class="material-symbols-outlined">directions_car</span>Veículos
                            </Link>
                        </li>
                    </ul>                
                }

            </div>       

            <nav class="navbar navbar-expand-lg py-4 py-lg-0 shadow desktop-hide">
                <div class="container">
                    <Logo />
                    <button class="navbar-toggler border-0 ms-auto" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar-user" aria-controls="top-navbar-user">
                        <span class="material-symbols-outlined">account_circle</span>
                    </button>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="top-navbar-user" aria-labelledby="top-navbarLabel">
                        <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar-user" aria-controls="top-navbar-user">
                            <div class="d-flex justify-content-between p-3">
                                <Logo />
                                <span class="material-symbols-outlined">close</span>
                            </div>
                        </button>
                        <ul class="navbar-nav ms-lg-auto p-4 p-lg-0">
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="#">
                                    <span class="material-symbols-outlined">manage_accounts</span>Editar Perfil
                                </a>
                            </li>
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="#">
                                    <span class="material-symbols-outlined">logout</span>Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar" aria-controls="top-navbar">
                        <span class="material-symbols-outlined">menu</span>
                    </button>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="top-navbar" aria-labelledby="top-navbarLabel">
                        <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar" aria-controls="top-navbar">
                            <div class="d-flex justify-content-between p-3">
                                <Logo />
                                <span class="material-symbols-outlined">close</span>
                            </div>
                        </button>
                        <ul class="navbar-nav ms-lg-auto p-4 p-lg-0">
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="#">
                                    <span class="material-symbols-outlined">bar_chart_4_bars</span>Relatórios
                                </a>
                            </li>
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="listagem-filiados.html">
                                    <span class="material-symbols-outlined">store</span>Afiliados
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div class="col-12 col-lg-2 mobile-hide invisible"></div>            
        </section>
    )
}

export default MenuLateral;
