import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { usuariosAPI } from "../../servicos/usuarios";


function Incluir() {

    const breadcrumbs = ['Admin', 'Usuario', 'Novo'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [us100_dsc_usuario, set100_dsc_usuario] = useState('');
    const [us100_dsc_email, set100_dsc_email] = useState('');
    const [us100_dsc_senha, set100_dsc_senha] = useState('');

    const [senhaConfirm, setSenhaConfirm] = useState('');

    let mostrSalvar = true;

    let navigate = useNavigate();
    const Voltar = () => {
        let path = '/admin/usuario';
        navigate(path);
    }

    const validaForm = (event) => {
        event.preventDefault();
        
        mostrSalvar = false;

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if (us100_dsc_usuario === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o nome.");
            mostrSalvar = true;
            return false;
        }

        if (us100_dsc_email === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o e-mail.");
            mostrSalvar = true;
            return false;
        }

        if ((us100_dsc_senha === '') || (senhaConfirm === '')) {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a senha.");
            mostrSalvar = true;
            return false;
        }

        if ((us100_dsc_senha !== senhaConfirm)) {
            setExibeErro(true);
            setMensagemErro("As senhas devem estar iguais.");
            mostrSalvar = true;
            return false;
        }

        SalvarUsuario();
    }

    async function SalvarUsuario() {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        usuariosAPI.post('/',
            {
                'us100_dsc_usuario': us100_dsc_usuario,
                'us100_dsc_email': us100_dsc_email,
                'us100_dsc_senha': us100_dsc_senha
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Usuário cadastrado com sucesso.');
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if (erro.response?.status === 401) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    }else{
                        setMensagemErro(erro.response.data.message);
                    }                    
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                mostrSalvar = true;                
            })


    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Novo Usuário' breadcrumbs={breadcrumbs} btnincluir={false} admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="us100_dsc_usuario" placeholder="" name="us100_dsc_usuario" value={us100_dsc_usuario} onChange={evento => set100_dsc_usuario(evento.target.value)} />
                                <label for="floatingInput">Nome do Usuário</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="email" class="form-control" id="us100_dsc_email" placeholder="name@example.com.br" name="us100_dsc_email" value={us100_dsc_email} onChange={evento => set100_dsc_email(evento.target.value)} />
                                <label for="floatingInput">E-mail</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="password" class="form-control" id="us100_dsc_senha" placeholder="" name="us100_dsc_senha" value={us100_dsc_senha} onChange={evento => set100_dsc_senha(evento.target.value)} />
                                <label for="floatingInput">Senha</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="password" class="form-control" id="fi100_dsc_senha_2" placeholder="" value={senhaConfirm} onChange={evento => setSenhaConfirm(evento.target.value)} />
                                <label for="floatingInput">Confirmação da Senha</label>
                            </div>
                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                {mostrSalvar &&
                                    <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Incluir;

