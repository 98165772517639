import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { emplacarAPI } from "../../servicos/emplacar";


function Incluir() {

    const breadcrumbs = ['Home', 'Emplacar', 'Novo'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [ve101_key_nfe, set101_key_nfe] = useState('');
    const [ve101_dsc_chassi, set101_dsc_chassi] = useState('');
    const [ve101_key_public, set101_key_public] = useState('');
    const [ve101_url_processo, set101_url_processo] = useState('');
    

    const [mostraSalvar, setmostraSalvar] = useState(true);
    const [mostraEnviar, setmostraEnviar] = useState(false);
    const [mostraDownload, setmostraDownload] = useState(false);
    const [mostraNovo, setmostraNovo] = useState(false);  

    let navigate = useNavigate();

    const Voltar = () => {
        let path = '/filiado/emplacar';
        navigate(path);
    }

    const NovoCadastro = () => {

        setExibeErro(false);
        setMensagemErro('');    
        setExibeSucesso(false);
        setMensagemSucesso('');    
        
        set101_key_nfe('');
        set101_dsc_chassi('');
        set101_key_public('');
        set101_url_processo('');

        setmostraSalvar(true);
        setmostraEnviar(false);
        setmostraDownload(false);
        setmostraNovo(false);  

    }

    const validaForm = (event) => {
        event.preventDefault();

        setmostraSalvar(false);

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if (ve101_key_nfe === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a chave da nota fiscal.");
            setmostraSalvar(true);
            return false;
        }

        if (ve101_dsc_chassi === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o chassi.");
            setmostraSalvar(true);
            return false;
        }

        RegistrarVeiculo();
    }

    async function RegistrarVeiculo() {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        emplacarAPI.post('/',
            {
                've101_key_nfe': ve101_key_nfe.replace( /\s/g,''),
                've101_dsc_chassi': ve101_dsc_chassi.replace( /\s/g,'')
            })
            .then(resposta => {
                set101_key_public(resposta.data.emplacar.ve101_key_public)
                setExibeSucesso(true);
                setMensagemSucesso('Registo criado com sucesso. Agora clique no botão DetranPR, para registrar o seu veículo.');
                setmostraEnviar(true);
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if (erro.response?.status === 401) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    }else{
                        setMensagemErro(erro.response.data.message);
                    }                    
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                setmostraSalvar(true);
            })

    }

    const EnviarDetran = (event) => {
        event.preventDefault();

        setmostraEnviar(false);

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        CriarProcesso();
    }

    async function CriarProcesso() {

        emplacarAPI.get(`/Processo/${ve101_key_public}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Processo (' + resposta.data.emplacar.ve101_num_processo + ') criado com sucesso. Clique no botão de Download.');
                set101_url_processo(resposta.data.emplacar.ve101_url_processo);
                setmostraDownload(true);
                setmostraNovo(true);
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if ((erro.response?.status === 401) || ((erro.response?.status === 406) && erro?.response?.data?.message === "Token Inválido") ) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    }else{
                        setMensagemErro(erro.response.data.message);
                    }                    
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                setmostraEnviar(true);
            })

    }


    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Registrar Veículo' breadcrumbs={breadcrumbs} btnincluir={false} admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                            <input type="hidden" class="form-control" id="ve101_key_public" placeholder="" value={ve101_key_public} onChange={evento => set101_key_public(evento.target.value)} />
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_key_nfe" placeholder="" value={ve101_key_nfe} onChange={evento => set101_key_nfe(evento.target.value)} />
                                <label for="floatingInput">Chave da NFs</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_dsc_chassi" placeholder="" name="ve101_dsc_chassi" value={ve101_dsc_chassi} onChange={evento => set101_dsc_chassi(evento.target.value)} />
                                <label for="floatingInput">Chassi</label>
                            </div>


                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                {mostraSalvar &&
                                    <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                                }
                                {mostraEnviar &&
                                    <button type="button" class="btn btn-primary-blue btn-primary" onClick={EnviarDetran}>DetranPR</button>
                                }
                                {mostraDownload &&
                                    <a class="btn btn-primary-blue btn-primary" href={ve101_url_processo} target="_blank">Download</a>
                                }                                
                                {mostraNovo &&
                                    <button type="button" class="btn btn-primary-blue btn-primary" onClick={NovoCadastro}>Novo</button>
                                }                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Incluir;

